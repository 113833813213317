<script>
  import { Carousel, Slide } from "vue-carousel";
  import {
      ArrowUpIcon,
      FacebookIcon,
      InstagramIcon,
      TwitterIcon,
      LinkedinIcon,
      ArrowRightIcon
  } from 'vue-feather-icons';
  
  import Navbar from "@/components/navbar";
  import Footer from "@/components/footer";
  
  
  
  
  /**
   * Page-aboutus-two component
   */
  export default {
      data() {
          return {}
      },
      components: {
          Navbar,
          Footer,
          ArrowUpIcon,
          Carousel,
          Slide,
          FacebookIcon,
          InstagramIcon,
          TwitterIcon,
          LinkedinIcon,
          ArrowUpIcon,
          ArrowRightIcon
      }
  }
  
  
  
  
  
  
  
  </script>
  
  
  
  
  
  <template>
    <div id="page">
  <div>
      <Navbar :nav-light="true" />
  
      <!-- Hero Start -->
      <section class="bg-half bg-violet-ball d-table w-100" style="background: url('images/background/satellite-1757979_1920.jpg') center bottom ">
  
          <div class="container">
            
              <div class="row justify-content-center">
                  <div class="col-lg-12 text-center">
                      <div class="page-next-level title-heading">
                          <h1 class="text-white title-dark"> About us <span v-b-tooltip.hover title="Another best-experienced digital marketing company and website development 
                  company, who provides freelance web developer and expert digital marketing service in Bangladesh. " class="my-color-1 font-weight-bold">*</span></h1>
                          <p class="text-white-50 para-desc mb-0 mx-auto">Start working with <span v-b-tooltip.hover title="Another best-experienced digital marketing company and website development 
                  company, who provides freelance web developer and expert digital marketing service in Bangladesh. ">Karukaj</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                          <div class="page-next">
                              <nav aria-label="breadcrumb" class="d-inline-block">
                                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                                      <li class="breadcrumb-item">
                                          <router-link to="/">Karukaj</router-link>
                                      </li>
                                      <li class="breadcrumb-item"><a href="/company">Company</a></li>
                                      <li class="breadcrumb-item active" aria-current="page">About</li>
                                  </ul>
                              </nav>
                          </div>
                      </div>
                  </div>
                  <!--end col-->
              </div>
              <!--end row-->
          </div>
          <!--end container-->
      </section>
      <!--end section-->
      <div class="position-relative">
          <div class="shape overflow-hidden text-white">
              <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
              </svg>
          </div>
      </div>
      <!-- Hero End -->
  
  
  
  
  
  
  







        <div class="container mt-60">
            <div class="row align-items-center">




            
              <div class="col-12 text-center">
                      <div class="section-title mb-4 pb-2">
                        <h4 class="title mb-4">Who we are <span v-b-tooltip.hover title="Another best-experienced digital marketing company and website development 
                  company, who provides freelance web developer and expert digital marketing service in Bangladesh">?</span></h4>
                          
                  <p class="text-muted mx-auto">
<span v-b-tooltip.hover title="Another best-experienced digital marketing company and website development 
                  company, who provides freelance web developer and expert digital marketing service in Bangladesh. " class="my-color-1 font-weight-bold"></span>  We are Incredible Digital Marketing Agency
                   in Bangladesh, you know us as <span v-b-tooltip.hover title="Another best-experienced digital marketing company and website development 
                  company, who provides freelance web developer and expert digital marketing service in Bangladesh. " class="text-muted">Karukaj DIGITAL</span>. Steam URL: karukaj.com.bd 
              </p>
                  
                  </div>
                  </div>






              <div class="col-lg-5 col-md-6 md-4 col-12 mt-sm-0 pt-2 pt-sm-0">





  
              <div class=" carousel-cell">
                <img width="80%" height="340" 
                  src="images/digital/about.png"
                  class="img-fluid mover"
                  alt=""
                />
              </div>

          <!--end col-->



                </div>






                <div class="col-lg-7 col-md-6 col-12">
                  <div class="card rounded bg-light shadow-lg border-0">
          <div class="card-body">

    
                      
                      
                     
                      <div class="section-title mb-4 pb-2">
                             








       



                         <p class="text-muted para-desc mb-0">      <br>   Our growth 
                  squad is focused on custom solutions in digital design & development arenas. 
                  Our skilled team can manage everything from large-scale disaster management to the most successful direct response campaigns. 
                  No matter what is your business, or business model, you’ll find a wealth of experience at your fingertips.</p>
  
       
  
     <br>   
   
  
  <h5 class="font-weight-bold">Our History</h5>
  
  <p class="text-muted para-desc mb-0"> <span class="text-muted">Karukaj</span> is a generation of Target Interact, a business process outsourcing company. 
  We've worked with some of the world's top corporations and small startups. 
  Our evolution since 2007 has given you an endless presence in a world of instability for decades. </p>
                          </div>


                    </div>       
                    
                    </div>



                </div>

          





            </div>
        </div>
        <!--end container-->











  
  
  
        <div class="container mt-100 mt-60">
  
                  <div class="col-12 text-center">
                      <div class="section-title mb-4 pb-2">
                          <h4 class="title mb-4">How we do ?</h4>
                          
                  <p class="text-muted mb-0"><span class="text-muted">Karukaj</span> create insightful and impactful branding, advertising campaigns, and websites that speak to the heart, <span v-b-tooltip.hover title="Our interactive after effect animation promo, video ads, photography, video editing, 
                  and video post production service, the services will charge your business several times more forever." 
                  >sparking engagement</span> and conversation.
                  </p>
                  
                  </div>
                  </div>
                  <!--end col-->
  
                  
          <div class="row">
            <div class="col-lg-4 col-md-6 col-12">
              <div class="media features feature-clean">
                <div class="icons my-color-1 text-center mx-auto">
                  <i class="uil uil-layer-group d-block rounded h3 mb-0"></i>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-1">
                    <a href="javascript:void(0)" class="text-dark"
                      >Capable & Well-prepared</a
                    >
                  </h5>
                  <p class="responsive-small text-muted mb-0">
                    We make sure that the people helping our customers are really well prepared. They get frequent feedback, intensive training, and evaluations of their work to keep their expertise at its best.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div class="media features feature-clean">
                <div class="icons my-color-1 text-center mx-auto">
                  <i class="uil uil-graduation-cap d-block rounded h3 mb-0"></i>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-1">
                    <a href="javascript:void(0)" class="text-dark">Friendly & helpful attitude</a>
                  </h5>
                  <p class="responsive-small text-muted mb-0">
                     We believe that the friendly and positive attitude, even in the hardest moments, is the best foundation for a solid long-term relationship. Our support team works not only with their brains but with their hearts too.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->
  
            <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2 mt-lg-0 pt-lg-0">
              <div class="media features feature-clean">
                <div class="icons my-color-1 text-center mx-auto">
                  <i class="uil uil-atom d-block rounded h3 mb-0"></i>
                </div>
                <div class="content ml-3">
                  <h5 class="mb-1">
                    <a href="javascript:void(0)" class="text-dark"
                      >Empowering the client</a
                    >
                  </h5>
                  <p class="responsive-small text-muted mb-0">
                    Our work not only aims to resolve the issues at hand, but to also empower our clients. We educate our clients how to become capable webmasters, able to resolve the site issues that may come their way.
                  </p>
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
  
        <!--end container-->  
  
  
  
  
  
  
  
          <!-- Companies Start -->
          <div class="container mt-100 mt-60">
              <div class="row justify-content-center">
                  <div class="col-12 text-center">
                      <div class="section-title mb-4 pb-2">
                          <h4 class="title mb-4">See everything about Karukaj Business</h4>
  
                <p class="text-muted mb-0">  <span v-b-tooltip.hover title="Karukaj Digital is especially popular among ancient website design company in Bangladesh. Currently, launches as a full-fledged Digital Marketing Agency." 
                  class="text-muted">Karukaj</span> strive to educate and collaborate with like-minded businesses to make a difference environmentally and socially.</p>
  
  
  
                      </div>
                  </div>
                  <!--end col-->
              </div>
              <!--end row-->
  
          </div>
          <!--end container-->
          <!-- Companies End -->
  
  
  
  
  
  
  
  
  
  
  
  
  












































  
  
  
  
     <section class="section">

  
  












      <div class="container mt-100 mt-60">



        <div class="card rounded bg-light shadow border-0">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-sm-7">
                <div class="text-sm-left">
                  <h4 class="mb-0">
                   Great Partnerships Grow Online Business 
                  </h4>
                  <p class="answer text-muted mb-0">
                  if you're passionate about driving results online, we'd love to hear from ....

                </p>
                </div>
              </div>
              <!--end col-->

              <div class="col-sm-5 mt-4 mt-sm-0">
                <div class="text-sm-right">
                  <a  title="Submit a Google Form" target="_blank" href="https://forms.gle/QrLXJWA2C7JcRBP78" class="btn btn-outline-primary"
                    ><i class="mdi mdi-file-document-outline"></i> Submit Google Form
                  </a>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row -->
          </div>
        </div>
      </div>
      <!--end container-->







  
  
  
        <div class="container">
          <div class="row justify-content-center">
         
            <!--end col-->
          </div>
          <!--end row-->
  
          <div class="row">
    
  

            <div class="col-12 text-center">
              <div class="section-title ">


  <br/>

                <p>Trusted by leading brands and agencies from
       around the world by over <span class="my-color-1">100+</span> of the world’s</p>
            
  

              </div>
            </div>



            <div class="col-12 mt-4 pt-2">
              <carousel
                id="customer-testi"
                ref="carousel"
                dir="ltr"
                :per-page="3"
                class="owl-carousel owl-theme"
                :autoplay="false"
                :loop="true"
              >
                <slide>
                  <div
                    class="card customer-testi text-center border rounded mx-2"
                  >
                    <div class="card-body">
                      <img
                        src="images/about-us/partner/01.png"
                        class="img-fluid avatar avatar-ex-sm mx-auto"
                        alt=""
                      />
                      <p class="responsive-hide text-muted mt-4">
                        “They have a unique imagination and have come up with some of the most creative ideas we’ve ever.”
  "
                      </p>
                    </div>
                  </div>
                </slide>
                <slide>
                  <div
                    class="card customer-testi text-center border rounded mx-2"
                  >
                    <div class="card-body">
                      <img
                        src="images/about-us/partner/02.png"
                        class="img-fluid avatar avatar-ex-sm mx-auto"
                        alt=""
                      />
                      <p class="responsive-hide text-muted mt-4">
                        “Their creative skills are an asset to the team and has a knack for thinking outside the box.”
                      </p>
                    </div>
                  </div>
                </slide>
                <slide>
                  <div
                    class="card customer-testi text-center border rounded mx-2"
                  >
                    <div class="card-body">
                      <img
                        src="images/about-us/partner/03.png"
                        class="img-fluid avatar avatar-ex-sm mx-auto"
                        alt=""
                      />
                      <p class="responsive-hide text-muted mt-4">
                        "They effectively manage our team and conduct specific exercises to strengthen the team."
                      </p>
                    </div>
                  </div>
                </slide>
                <slide>
                  <div
                    class="card customer-testi text-center border rounded mx-2"
                  >
                    <div class="card-body">
                      <img
                        src="images/about-us/partner/04.png"
                        class="img-fluid avatar avatar-ex-sm mx-auto"
                        alt=""
                      />
                      <p class="responsive-hide text-muted mt-4">
                        "They provides employees with the resources and training required to fulfill a responsibility. "
                      </p>
                    </div>
                  </div>
                </slide>
                <slide>
                  <div
                    class="card customer-testi text-center border rounded mx-2"
                  >
                    <div class="card-body">
                      <img
                        src="images/about-us/partner/05.png"
                        class="img-fluid avatar avatar-ex-sm mx-auto"
                        alt=""
                      />
                      <p class="responsive-hide text-muted mt-4">
                        "They maintains a transparency and knowledge-sharing across all levels in your department.”
                      </p>
                    </div>
                  </div>
                </slide>
                <slide>
                  <div
                    class="card customer-testi text-center border rounded mx-2"
                  >
                    <div class="card-body">
                      <img
                        src="images/about-us/partner/06.png"
                        class="img-fluid avatar avatar-ex-sm mx-auto"
                        alt=""
                      />
                      <p class="responsive-hide text-muted mt-4">
                        "Karukaj team are creative and treat every employee in your team equally and respectfully"
                      </p>
                    </div>
                  </div>
                </slide>
              </carousel>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
   









      <!--end section-->
      <!-- Testi End -->
  
  
  
  
  
  
  
  
  













  

  
  
  
  
  
  
  
  
    </section>
  
 

  
  
  
  











    <section
      class="section"
      style="background: url('images/background/satellite-1757979_1920.jpg') center bottom"
    >
      <div class="explore-login-backg"></div>



      
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-md-7">









            <div class="section-title text-md-left text-center">




              <h6 class="my-color-1">
                Like our respected native clients;</h6>
              <h4 class="title text-light mb-4">
                let's start your project too
              </h4>








              <p class="text-dim mb-0 para-desc">You are just a few clicks away. So, save your time to create something awesome that will make you proud and dominate your competition. 
 </p>


             

              <div class="row justify-content-center">
                <div class="col-lg-12 mt-4">
                  <carousel
                id="owl-fade"
                class="owl-carousel owl-theme"
                dir="ltr"
                :per-page="1"
                :autoplay="true"
                :loop="true"
                  >
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/LT-biplop-chakma-min.png"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                                                        "Top-notch services! The team at Karukaj is attentive, 
                            efficient, and wonderful to work with. We appreciate all the work they do for us! ."


                          </p>
                          <h6 class="my-color-1">
                            Lt Commander Biplob Chakma <br>
                            <small class="text-muted">- Director Project, Rahim and Sons Information System (RASIS)</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/major-tanvir-ahmed1-min.png"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star-half text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                                                        " Simply the best in the business of marketing and design. So helpful, 
                            reliable, fast, great communication. They won’t disappoint you! "


                          </p>
                          <h6 class="my-color-1">
                            Major Tanvir Ahmed Jaigirder <br>
                            <small class="text-white">- CEO, G9Vision Limited</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/arch-imtiaz-ahmed-min.png"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            " Really appreciated their attention to detail and patience as 
                            we worked through our new website, and we were extremely pleased"

                          </p>
                          <h6 class="my-color-1">
                            Architect Syed Imtiaz Ahmed <br>
                            <small class="text-muted">- Founder, Dimension 3 Architecture and Interior</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/shawon-vaiya1-min.png"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            "I continue to work with them, and look forward to 
                            doing so for the foreseeable future! "
                          </p>
                          <h6 class="my-color-1">
                            Architect Raisul Rafi Shaon <br>
                            <small class="text-muted">- Lead Architect. Design And Architect (DNA)</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/sisco1-min.png"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                                                        " Karukaj team is very passionate and strategic with their 
                            marketing approach. I truly feel as though they care about my business..
                            "


                          </p>
                          <h6 class="my-color-1">
                            M. Nazim Uddin <br>
                            <small class="text-muted">- Managing Director, Sisco Security Service Co</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                    <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/01.png"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            " Very professional team! They worked on our website 
                            and redesigned it the way we wanted "
                          </p>
                          <h6 class="my-color-1">
                            Musa Biswas <br>
                            <small class="text-muted">- Owner/Managing Director at Biswas Automobiles</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                     <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/mehedi-vaiya1-min.png"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            " The creative team at Karukaj Digital not only produced 
                            killer designs but a really cool and unique user experience "
                          </p>
                          <h6 class="my-color-1">
                            Architect Kazi Mizanur Rahman <br>
                            <small class="text-muted">- Sr. Architect, EDMAC Engineering Consultant</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                                        <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/02in.png"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            " The only company I've ever worked with that actually got results 
                            for my SEO rankings, and happens to have a great team with support. "
                          </p>
                          <h6 class="my-color-1">
                            Neamot Ullah Seraji <br>
                            <small class="text-muted">- Founder, Omullo Health Blog</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                      <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/nasim.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            " Amazing team. Very professional. 
                            Karukaj is my go to for all of my marketing needs! "
                          </p>
                          <h6 class="my-color-1">
                          MS. Tania Toma <br>
                            <small class="text-muted"> - Co-Founder, Adiyat Fashion</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                        <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/abirvai.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            " It’s been 7 months since we ran our social media 
                            campaign with Karukaj. Now, we are happy engagement. "
                          </p>
                          <h6 class="my-color-1">
                           Moaz Bin Seraji <br>
                            <small class="text-muted"> - Co-Founder, Medfoli Corporation</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>

                       <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/sukhm-1.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            "  I am very pleased with the presentation capabilities, 
                            solid performance, and most of all the support provided. "
                          </p>
                          <h6 class="my-color-1">
                           Sukhmon Sultan <br>
                            <small class="text-muted"> - Film Director & Writer, Noise Productions ltd </small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                     <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/khanvai.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            " I'm so glade to have him to handle my project and 
                            I look forward to future collaborations."
                          </p>
                          <h6 class="my-color-1">
                           Hanif Khan <br>
                            <small class="text-muted"> - Proprietor, Khan Multi Brand (Automobile Equipment Importers)</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                      <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/20-1.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            "  True expert in the field of digital marketing and 
                            highly motivated to share knowledge. "
                          </p>
                          <h6 class="my-color-1">
                           Abdullah-Al-Mamunai <br>
                            <small class="text-muted"> - Co-Founder, Lazbe Online Shopping & Retail</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                       <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/m1.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            " Nothing this cool has ever been created before in such 
                            a short time. I appreciate the hard work and keeping it on schedule.. "
                          </p>
                          <h6 class="my-color-1">
                            Nate Sampson <br>
                            <small class="text-muted">- Founder of BeWeddy All-in-One Wedding Platform</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                                         <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/88.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            "'Katukaj' demonstrated high 
                            professionalism, subject matter expertise and eSxceeded our expectations in every way.. "
                          </p>
                          <h6 class="my-color-1">
                            Nafiz Ahmed <br>
                            <small class="text-muted"> - Managing Director, 'ASource' Buying House</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                                                             <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/is1.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            "Ensure our best digital experience for ultimate goal and their onboard 
                             articles are very helpful, we get to learn many new things. "
                          </p>
                          <h6 class="my-color-1">
                            Ismail Hossain <br>
                            <small class="text-muted"> - Managing Director, U'Tacos est la chaîne de Restaurants</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                                                             <Slide>
                      <div class="media customer-testi m-2">
                        <img
                          src="images/client/ss1.jpg"
                          class="avatar avatar-small mr-3 rounded shadow"
                          alt=""
                        />
                        <div
                          class="media-body content p-3 shadow rounded background--black-clients position-relative"
                        >
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                            <li class="list-inline-item">
                              <i class="mdi mdi-star text-warning"></i>
                            </li>
                          </ul>
                          <p class="text-light mt-2">
                            "'They have managed our entire YouTube channel with graphics, 
                            which is excellent and trendy with keywords & backlinks "
                          </p>
                          <h6 class="my-color-1">
                            Sheikh Nizami<br>
                            <small class="text-muted"> - Singer and Music Composer, Band Name: Melody Eternal</small>
                          </h6>
                        </div>
                      </div>
                    </Slide>
                  </carousel>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>









            

          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="card rounded shadow border-0">
              <div class="card-body">
                <h5 class="text-center">Become A Member</h5>

                <form>
                  <div class="row mt-4">
                    <div class="col-lg-12">
                      <iframe width="auto" height="360" src="https://members.karukaj.net/forms/wtl/aa4793a5caccf127bf6bbd2c5d5fb812" frameborder="0" sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin" allowfullscreen></iframe>
                    </div>
                    <!--end col-->

                

                    <div class="col-lg-12 mb-0">
                  
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
                <!--end form-->
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->




      
    </section>
    <!--end section-->


















<div class="position-relative">
        <div class="shape overflow-hidden text-footer">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!--end section-->
















      <Footer />
      <!-- Footer End -->
      <!-- Back to top -->
      <a
      href="javascript: void(0);"
      class="btn btn-light btn-icon back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
      <!-- Back to top -->
  </div>
  </div>
  </template>
  
  <style>
  .modal-content {
      background-color: transparent;
  }
  </style>
  